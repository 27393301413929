<template>
  <div :style="getWrapperMargin">
    <div class="d-flex gap-6">
      <v-combobox
        v-click-outside="onClickOutside"
        :disabled="disabledInput"
        :filter="onFilter"
        :height="56"
        :items="lodash.cloneDeep(items)"
        :menu-props="{
          value: selectedMenu,
        }"
        :placeholder="placeholder"
        :search-input.sync="search"
        :value="selectedFiltersToSearch"
        append-icon="mdi-filter-outline"
        class="v-text-field--outlined__rectangle mx-auto"
        data-test="collections_search_field"
        item-text="field"
        item-value="index"
        prepend-inner-icon="mdi-magnify"
        style="max-width: 1225px"
        return-object
        rounded
        solo
        multiple
        clearable
        deletable-chips
        persistent-hint
        single-line
        attach
        @click.stop
        @click:append="onKeydown(true)"
        @click:clear="$emit('search', [])"
        @click:prepend-inner="$emit('search', selectedFiltersToSearch)"
        @input="onAddItem"
        @keydown.40="onKeydown"
        @keydown.enter="onPressEnter"
        @keydown.delete="onKeydownDelete"
        @update:list-index="updateSelectedListIndex">
        <template #selection="{ item, index }">
          <component
            :is="item.component"
            v-bind="$attrs"
            :name="item.field"
            :item-value="item.value"
            :items="item.items"
            :alias="item.alias"
            :autofocus="item.autofocus"
            :index="index"
            @update="onUpdate(index, $event)"
            @remove="onRemove(index, $event)" />
        </template>
        <template #item="{ item }">
          <v-list-item @click="onAddItem([...selectedFiltersToSearch, item ], !!search)">
            {{ item.field }} <span v-if="search"> : {{ search }}</span>
          </v-list-item>
        </template>
      </v-combobox>
      <div class="d-flex flex-column search-filter-toggles">
        <WorkspaceOnly />
        <v-switch
          v-model="verifiedOnly"
          class="d-inline-block ml-2"
          label="Verified Only"
          inset />
      </div>
    </div>
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      class="mx-auto"
      style="max-width: 1225px">
      <FilterList
        :filter-list="items"
        :disabled="disabledInput"
        @select="onAddItem([...selectedFiltersToSearch, $event ])" />
    </div>
  </div>
</template>
<script>
import {
  cloneDeep, isEmpty,
} from 'lodash';
import {
  PRODUCT_AND_COLLECTION_FILTERS,
  VERIFIED,
} from '@/constants/searching/searchingFilters';
import {
  EventBus,
  START_NEW_COMMUNITY_SEARCH,
} from '@/utils/eventBus';
import defaultItems from '@/constants/searching/searchingFieldsToRendering';
import ScheduleCells from '@/services/graphql/scheduleCells';
export default {
  name: 'SearchFilter',
  components: {
    FilterList: () => import('@/components/SearchFilter/SearchFilterList'),

    /**
     * defined in defaultItems
     */
    InputText: () => import('@/components/SearchFilter/SearchFilterInputText'),
    InputDropdown: () => import ('@/components/SearchFilter/SearchFilterInputDropdown'),
    InputBool: () => import ('@/components/SearchFilter/SearchFilterInputBoolean'),
    /**
     * search inside workspace only
     */
    WorkspaceOnly: () => import('@/components/SearchFilter/SearchFilterWorkspaceOnly'),
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    queryFilters: {
      type: Object,
      default: null,
    },
    filtersToUse: {
      type: Array,
      default: () => (PRODUCT_AND_COLLECTION_FILTERS),
    },
    updateFromProps: {
      type: Boolean,
      default: false,
    },
    testValue: {
      type: String,
      default: '',
    },
    isUseWrapperMargin: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedListIndex: -1,
    selectedMenu: false,
    defaultItems,
    selectedFiltersToSearch: [],
    search: null,
  }),
  computed: {
    getWrapperMargin() {
      const { isUseWrapperMargin: use, itemsLength: leng, selectedMenu } = this;
      if (!use || !leng || !selectedMenu) return null;
      const ITEM_MARGIN = 25;
      return {
        marginBottom: ITEM_MARGIN * leng + 'px',
      };
    },
    items() {
      const { defaultItems: items, filtersToUse: filters } = this;
      return items.filter(({ alias }) => filters.includes(alias));
    },
    itemsLength() {
      const { items } = this;
      return items.length;
    },
    getKeywordFilter() {
      return this.items.find(({ field }) => field == 'Keyword');
    },
    getLastSelectedItemIndex() {
      const { selectedFiltersToSearch: arr, selectedFilterLength: leng } = this;
      if (!leng) return 0;
      const { index = 0 } = arr[leng - 1] ?? {
      };
      return index;
    },
    getDropdownFilters() {
      return this.items.filter(({ dataType }) => dataType == 'array');
    },
    selectedFilterLength() {
      const { selectedFiltersToSearch: arr } = this;
      if (!arr) return 0;
      return arr.length;
    },
    verifiedOnly: {
      get() {
        return this.selectedFiltersToSearch.some((filter) => filter.alias === VERIFIED && filter.value === true);
      },
      set(value) {
        this.toggleVerifiedFilter(value);
      },
    },
  },
  watch: {
    queryFilters: {
      handler() {
        this.setSelectedAccordingToQueryFilters();
      },
    },
  },
  async created() {
    await this.getDropdownValues();
    this.setDropdownValuesToSelected();
    this.setSelectedAccordingToQueryFilters();
  },
  mounted() {
    EventBus.$on(START_NEW_COMMUNITY_SEARCH, () => {
      this.startBlankVerifiedSearch();
    });
  },
  destroyed() {
    EventBus.$off(START_NEW_COMMUNITY_SEARCH);
  },
  methods: {
    async onKeydownDelete() {
      if (!this.selectedFilterLength || this.search) {
        return;
      }
      await this.$nextTick();
      this.$emit('search', this.selectedFiltersToSearch);
    },
    setDropdownValuesToSelected() {
      const mappedFilters = (this.selectedFiltersToSearch || []).map(filter => {
        const { items = [] } = this.getDropdownFilters.find(item => item.field == filter.field) ?? {
        };
        if (filter.dataType == 'array') {
          return {
            ...filter,
            items,
          };
        }
        return filter;
      });
      this.selectedFiltersToSearch = mappedFilters;
    },
    async getDropdownValues() {
      this.defaultItems = await Promise.all(this.items.map(async item => {
        const { field, dataType } = item;
        if (dataType !== 'array') return item;
        try {
          const { data } = await ScheduleCells.getDropdownValues({
            col: field,
          });
          const { fixedValues: items = [] } = data?.response ?? {
          };
          return {
            ...item,
            items,
          };
        } catch (err) {
          console.log('err getDropdownValues', err);
          return {
            ...item,
          };
        }
      }));
    },
    setSelectedAccordingToQueryFilters() {
      if (isEmpty(this.queryFilters) || !this.updateFromProps) {
        return;
      }
      this.selectedFiltersToSearch = Object.keys(this.queryFilters).reduce((acc = [], filterName) => {
        const filter = cloneDeep(this.items.find(({ alias }) => alias.toLowerCase() == filterName.toLowerCase()));
        let values = this.queryFilters[filterName];
        if (!Array.isArray(values)) {
          values = [values];
        }
        values.forEach(value => {
          acc.push({
            ...filter,
            value,
          });
        });
        return acc;
      }, []);
    },
    onClickOutside() {
      this.selectedMenu = false;
    },
    onKeydown(toggleMode = false) {
      this.selectedMenu = toggleMode ? !this.selectedMenu : true;
    },
    onFilter(item, queryText) {
      if (item.dataType == 'text') {
        return true;
      }
      if (item.dataType == 'array') {
        return item.items.find(item => {
          return item.toLowerCase().includes(queryText.toLowerCase());
        });
      }
      return false;
    },
    onRemove(index, search = false) {
      this.selectedFiltersToSearch.splice(index, 1);
      if (search) {
        this.$emit('search', this.selectedFiltersToSearch);
      }
      return this;
    },
    updateSelectedListIndex(index) {
      this.selectedListIndex = index;
      return this;
    },
    onAddItem(items, update = false) {
      this.selectedMenu = false;
      items = items.filter(item => item?.field);
      items = this.keepOnlyLatestIsVerified(items);

      if (items.length > this.selectedFilterLength) {
        items[items.length - 1] = {
          index: this.selectedFilterLength + 1,
          ...items[items.length - 1],
        };
      }
      this.selectedFiltersToSearch = items;
      if (update) {
        this.onUpdate(this.getLastSelectedItemIndex - 1, {
          value: this.search,
        });
        this.search = '';
      }
      return this;
    },
    onUpdate(index, { value, final = false }) {
      if (isNaN(index)) {
        return;
      }
      const item = this.selectedFiltersToSearch[index];
      this.$set(item, 'value', value);
      if (item.dataType == 'array' && !final) {
        this.$set(item, 'autofocus', true);
        return this;
      }
      this.$emit('search', this.selectedFiltersToSearch);
      return this;
    },
    async onPressEnter() {
      if (this.selectedListIndex !== -1) {
        return;
      }
      if (!this.search) {
        await this.$nextTick();
        this.$emit('search', this.selectedFiltersToSearch);
        return;
      }
      this.onAddItem([
        ...this.selectedFiltersToSearch,
        this.getKeywordFilter,
      ], true);
    },
    async toggleVerifiedFilter(value) {
      const curr = this.selectedFiltersToSearch;
      if (value) {
        const verifiedItem = this.defaultItems.find((item) => item.alias === VERIFIED);
        if (verifiedItem) {
          verifiedItem.value = true;
          const items = [...this.selectedFiltersToSearch, verifiedItem];
          this.selectedFiltersToSearch = this.keepOnlyLatestIsVerified(items);
        }
      } else {
        this.selectedFiltersToSearch = curr.filter((item) => item.alias !== VERIFIED);
      }
      await this.$nextTick();
      this.$emit('search', this.selectedFiltersToSearch);
    },
    keepOnlyLatestIsVerified(items) {
      if (!items || items.length <= 1) return items;
      const last = items[items.length - 1];
      if (last.alias != VERIFIED) return items;

      return items.filter((item, index) => {
        return item.alias != VERIFIED || index === items.length - 1;
      });
    },
    async startBlankVerifiedSearch() {
      const verifiedItem = this.defaultItems.find((item) => item.alias === VERIFIED);
      if (verifiedItem) {
        verifiedItem.value = true;
        this.selectedFiltersToSearch = [verifiedItem];
      }
      await this.$nextTick();
      this.$emit('search', this.selectedFiltersToSearch);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
::v-deep .v-text-field--outlined__rectangle {
  height: initial !important;
  .v-input__control > .v-input__slot {
    padding-right: 12px;
    padding-left: 12px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%) !important;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 0 !important;
    }
  }
  .v-select__slot {
    padding-top: 0 !important;
  }
}
::v-deep .v-select .v-input__icon--append .v-icon {
  transform: initial !important;
}
::v-deep .v-chip {
  height: 36px !important;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: fit-content !important;
  }
}
::v-deep .v-chip__content {
  font-size: 14px!important;
}
.search-filter-toggles {
  ::v-deep .v-input__control {
    max-height: 36px !important;
  }
  ::v-deep .v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  // When toggle is alone - can't flex-center, search bar has height outside the bar!
  div:only-child {
    margin-top: 18px !important;
  }
}
</style>
