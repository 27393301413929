var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.getWrapperMargin)},[_c('div',{staticClass:"d-flex gap-6"},[_c('v-combobox',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"v-text-field--outlined__rectangle mx-auto",staticStyle:{"max-width":"1225px"},attrs:{"disabled":_vm.disabledInput,"filter":_vm.onFilter,"height":56,"items":_vm.lodash.cloneDeep(_vm.items),"menu-props":{
        value: _vm.selectedMenu,
      },"placeholder":_vm.placeholder,"search-input":_vm.search,"value":_vm.selectedFiltersToSearch,"append-icon":"mdi-filter-outline","data-test":"collections_search_field","item-text":"field","item-value":"index","prepend-inner-icon":"mdi-magnify","return-object":"","rounded":"","solo":"","multiple":"","clearable":"","deletable-chips":"","persistent-hint":"","single-line":"","attach":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"click":function($event){$event.stopPropagation();},"click:append":function($event){return _vm.onKeydown(true)},"click:clear":function($event){return _vm.$emit('search', [])},"click:prepend-inner":function($event){return _vm.$emit('search', _vm.selectedFiltersToSearch)},"input":_vm.onAddItem,"keydown":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==40){ return null; }return _vm.onKeydown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onPressEnter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.onKeydownDelete.apply(null, arguments)}],"update:list-index":_vm.updateSelectedListIndex},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c(item.component,_vm._b({tag:"component",attrs:{"name":item.field,"item-value":item.value,"items":item.items,"alias":item.alias,"autofocus":item.autofocus,"index":index},on:{"update":function($event){return _vm.onUpdate(index, $event)},"remove":function($event){return _vm.onRemove(index, $event)}}},'component',_vm.$attrs,false))]}},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('v-list-item',{on:{"click":function($event){return _vm.onAddItem(_vm.selectedFiltersToSearch.concat( [item] ), !!_vm.search)}}},[_vm._v(" "+_vm._s(item.field)+" "),(_vm.search)?_c('span',[_vm._v(" : "+_vm._s(_vm.search))]):_vm._e()])]}}])}),_c('div',{staticClass:"d-flex flex-column search-filter-toggles"},[_c('WorkspaceOnly'),_c('v-switch',{staticClass:"d-inline-block ml-2",attrs:{"label":"Verified Only","inset":""},model:{value:(_vm.verifiedOnly),callback:function ($$v) {_vm.verifiedOnly=$$v},expression:"verifiedOnly"}})],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"1225px"}},[_c('FilterList',{attrs:{"filter-list":_vm.items,"disabled":_vm.disabledInput},on:{"select":function($event){return _vm.onAddItem(_vm.selectedFiltersToSearch.concat( [$event] ))}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }